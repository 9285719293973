var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover.html",
      value: _vm.tooltipHtml,
      expression: "tooltipHtml",
      modifiers: {
        "hover": true,
        "html": true
      }
    }],
    staticClass: "feeds__copy-icon",
    class: _vm.isCopied ? 'fa fa-check text-success' : 'fa fa-copy',
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.handleCopy.apply(null, arguments);
      }
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }