var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "feed-icons"
  }, [_vm.isFundamentalFeed ? _c('img', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "feed-icon",
    attrs: {
      "src": require("../../../../assets/icons/fundamental.svg"),
      "title": "Fundamental price value"
    }
  }) : _vm._e(), _vm.isTwap30 ? _c('img', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "feed-icon",
    attrs: {
      "src": require("../../../../assets/icons/twap-30.svg"),
      "title": "Time-weighted average price - 30 min"
    }
  }) : _vm._e(), _vm.isTwap60 ? _c('img', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "feed-icon",
    attrs: {
      "src": require("../../../../assets/icons/twap-60.svg"),
      "title": "Time-weighted average price - 60 min"
    }
  }) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }