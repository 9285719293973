var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crypto-picker"
  }, [_c('SearchableMultiselectDropdown', {
    attrs: {
      "items": _vm.formattedItems,
      "value": _vm.value,
      "button-text-all": "All Sources",
      "button-text-selected": "Selected sources"
    },
    on: {
      "input": _vm.handleInput
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }