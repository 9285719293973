var render = function render(){
  var _vm$feedData, _vm$feedData2, _vm$feedData2$apiValu, _vm$feedData3, _vm$feedData4, _vm$feedData5, _vm$feedData5$explore, _vm$feedData6, _vm$feedData6$network, _vm$feedData7, _vm$feedData8, _vm$feedData9, _vm$feedData9$loaders, _vm$feedData10, _vm$feedData10$apiVal;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "feed-details"
  }, [_c('dl', {
    staticClass: "stats-grid"
  }, [_c('div', {
    staticClass: "stat-item"
  }, [_c('dt', {
    staticClass: "stat-title"
  }, [_vm._v("Answer")]), _vm.feedData ? _c('dd', {
    staticClass: "stat-value"
  }, [_c('span', [_vm._v(_vm._s(_vm.currencySymbolMap[(_vm$feedData = _vm.feedData) === null || _vm$feedData === void 0 ? void 0 : _vm$feedData.denomination] || _vm.feedData.denomination))]), _c('strong', [_vm._v(_vm._s(_vm.formatPriceWithoutCurrency((_vm$feedData2 = _vm.feedData) === null || _vm$feedData2 === void 0 ? void 0 : (_vm$feedData2$apiValu = _vm$feedData2.apiValues) === null || _vm$feedData2$apiValu === void 0 ? void 0 : _vm$feedData2$apiValu.value, _vm.sUSDe_RATE)))]), _c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "timestamp",
    attrs: {
      "title": _vm.feedData.updateTime
    }
  }, [_vm._v("Updated " + _vm._s(_vm.feedData.humanUpdateTime))])]) : _vm._e()]), _c('div', {
    staticClass: "stat-item"
  }, [_c('dt', {
    staticClass: "stat-title"
  }, [_vm._v("Network")]), _c('dd', {
    staticClass: "stat-value-wrapper"
  }, [_c('div', {
    staticClass: "stat-value"
  }, [_vm.feedData ? _c('img', {
    staticClass: "feeds__token-image small",
    attrs: {
      "src": (_vm$feedData3 = _vm.feedData) === null || _vm$feedData3 === void 0 ? void 0 : _vm$feedData3.network.image,
      "alt": (_vm$feedData4 = _vm.feedData) === null || _vm$feedData4 === void 0 ? void 0 : _vm$feedData4.network.name
    }
  }) : _vm._e(), _c('span', {
    staticClass: "applicant-info__text"
  }, [_c('a', {
    attrs: {
      "href": (_vm$feedData5 = _vm.feedData) === null || _vm$feedData5 === void 0 ? void 0 : (_vm$feedData5$explore = _vm$feedData5.explorer) === null || _vm$feedData5$explore === void 0 ? void 0 : _vm$feedData5$explore.explorerUrl,
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s((_vm$feedData6 = _vm.feedData) === null || _vm$feedData6 === void 0 ? void 0 : (_vm$feedData6$network = _vm$feedData6.network) === null || _vm$feedData6$network === void 0 ? void 0 : _vm$feedData6$network.name) + " ")])])])])]), _c('div', {
    staticClass: "stat-item"
  }, [_c('dt', {
    staticClass: "stat-title"
  }, [_vm._v("Symbol")]), _c('dd', {
    staticClass: "stat-value"
  }, [_vm._v(_vm._s((_vm$feedData7 = _vm.feedData) === null || _vm$feedData7 === void 0 ? void 0 : _vm$feedData7.feed))])]), _c('div', {
    staticClass: "stat-item"
  }, [_c('dt', {
    staticClass: "stat-title"
  }, [_vm._v("Deviation threshold")]), _c('dd', {
    staticClass: "stat-value"
  }, [_vm._v(_vm._s((_vm$feedData8 = _vm.feedData) === null || _vm$feedData8 === void 0 ? void 0 : _vm$feedData8.deviation))])]), _vm.feedData ? _c('div', {
    staticClass: "stat-item"
  }, [_c('dt', {
    staticClass: "stat-title"
  }, [_vm._v("Heartbeat")]), _c('dd', {
    staticClass: "stat-value"
  }, [(_vm$feedData9 = _vm.feedData) !== null && _vm$feedData9 !== void 0 && (_vm$feedData9$loaders = _vm$feedData9.loaders) !== null && _vm$feedData9$loaders !== void 0 && _vm$feedData9$loaders.blockTimestamp && ((_vm$feedData10 = _vm.feedData) === null || _vm$feedData10 === void 0 ? void 0 : (_vm$feedData10$apiVal = _vm$feedData10.apiValues) === null || _vm$feedData10$apiVal === void 0 ? void 0 : _vm$feedData10$apiVal.timestamp) == null ? _c('Loader', {
    staticClass: "feeds__loader"
  }) : _c('span', {
    staticClass: "feeds__timestamp"
  }, [_vm.heartbeatIsNumber(_vm.feedData.heartbeat) ? _c('span', [_c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.feedData.heartbeatTitle))]), _c('to-date-counter', {
    staticClass: "ml-2",
    attrs: {
      "interval": _vm.feedData.heartbeatInterval,
      "duration": _vm.feedData.heartbeat
    }
  })], 1)]) : _c('div', [_c('span', {
    staticClass: "cron-trigger",
    attrs: {
      "id": "cron-trigger-".concat(_vm.feedData.layer_id)
    }
  }, [_c('span', [_vm._v("Cron: " + _vm._s(_vm.feedData.heartbeatTitle))]), _c('cron-counter', {
    staticClass: "ml-2",
    attrs: {
      "crons": _vm.feedData.heartbeat
    }
  })], 1)])])], 1)]) : _vm._e()]), _vm.feedData ? _c('contract-address', {
    staticClass: "contract-address",
    attrs: {
      "item": _vm.feedData,
      "disable-truncate": "",
      "separate-labels": false
    }
  }) : _vm._e(), _c('div', {
    staticClass: "feed-chart"
  }, [_c('div', {
    staticClass: "crypto-dropdown chart"
  }, [_c('b-form-checkbox', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "crypto-checkbox-list",
    attrs: {
      "variant": "danger",
      "title": "Opens block transaction explorer on chart point click"
    },
    model: {
      value: _vm.openExplorerOnClick,
      callback: function callback($$v) {
        _vm.openExplorerOnClick = $$v;
      },
      expression: "openExplorerOnClick"
    }
  }, [_vm._v(" Block explorer "), _c('i', {
    staticClass: "fa fa-info-circle"
  })])], 1), _vm.currentChartData && !_vm.isLoading ? _c('layer-chart', {
    attrs: {
      "data": _vm.currentChartData,
      "range": _vm.currentRange,
      "denomination": _vm.feedData.denomination,
      "special-denomination": _vm.sUSDe_RATE,
      "duplicated-ranges": _vm.duplicateRanges.flat(),
      "currency": _vm.getCurrency(_vm.feedData.token),
      "maxDataPoints": 250
    },
    on: {
      "data-point-click": _vm.handleDataPointClick,
      "range-change": _vm.handleRangeChange
    }
  }) : _c('div', {
    staticClass: "loading-container"
  }, [_c('vue-loaders-ball-beat', {
    attrs: {
      "color": "var(--redstone-red-color)",
      "scale": "1"
    }
  })], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }