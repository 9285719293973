var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-navbar', {
    staticClass: "header d-print-none app-header"
  }, [_c('b-nav', [_c('b-nav-item', [_c('a', {
    staticClass: "fs-lg d-xl-none",
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.toggleSidebarMenu
    }
  }, [_c('i', {
    staticClass: "fi flaticon-menu"
  })])])], 1), _c('b-nav', {
    staticClass: "w-25 w-md-auto"
  }, [_c('b-form', {
    staticClass: "ml-1",
    attrs: {
      "inline": ""
    }
  }, [_c('b-form-group', {
    staticStyle: {
      "margin-bottom": "0"
    }
  }, [_vm.showSearchInputInHeader ? _c('b-input-group', {
    staticClass: "input-group-no-border",
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('b-input-group-text', [_c('i', {
          staticClass: "fi flaticon-search-2"
        })])];
      },
      proxy: true
    }, {
      key: "append",
      fn: function fn() {
        return [_vm.searchTerm ? _c('b-input-group-text', {
          on: {
            "click": _vm.resetSearch
          }
        }, [_c('i', {
          staticClass: "fa fa-times"
        })]) : _vm._e()];
      },
      proxy: true
    }], null, false, 1145116244)
  }, [_c('b-form-input', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.focus.left",
      value: _vm.hasFeedsFilters ? 'You have filters selected, they will reset on search ' : '',
      expression: "\n              hasFeedsFilters\n                ? 'You have filters selected, they will reset on search '\n                : ''\n            ",
      modifiers: {
        "focus": true,
        "left": true
      }
    }],
    attrs: {
      "id": "search-input",
      "placeholder": "Search..."
    },
    model: {
      value: _vm.searchTerm,
      callback: function callback($$v) {
        _vm.searchTerm = $$v;
      },
      expression: "searchTerm"
    }
  })], 1) : _c('span', {
    on: {
      "click": _vm.safeGoBack
    }
  }, [_c('i', {
    staticClass: "fa flaticon-chevron-back",
    attrs: {
      "role": "button"
    }
  })])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }