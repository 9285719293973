var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isLoading ? _c('Loader', {
    staticClass: "feeds__loader"
  }) : _c('span', {
    staticClass: "feeds__timestamp"
  }, [_vm.heartbeatIsNumber(_vm.heartbeat) ? _c('span', [_c('to-date-counter', {
    attrs: {
      "duration": _vm.heartbeat
    }
  })], 1) : _c('div', [_c('span', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "id": "cron-trigger-".concat(_vm.layer_id)
    }
  }, [_c('to-date-counter', {
    staticClass: "d-inline",
    attrs: {
      "duration": _vm.nearestCron(_vm.heartbeat)
    }
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }