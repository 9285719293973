var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "data-loader"
  }, [_c('i', {
    staticClass: "la la-spinner la-spin",
    style: {
      fontSize: _vm.size + 'px'
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }