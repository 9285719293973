var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "source-details"
  }, [_c('h1', [_c('img', {
    attrs: {
      "src": _vm.sourceDetails.logoURI,
      "alt": _vm.sourceId
    }
  }), _vm._v(" " + _vm._s(_vm.sourceId) + " ")]), _c('div', {
    staticClass: "chart-container"
  }, [_c('SourceChartsContainer', {
    attrs: {
      "stats": _vm.sourceStats
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }