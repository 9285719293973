var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: [{
      root: true,
      showSidebar: _vm.showSidebar
    }, 'sing-dashboard']
  }, [_c('Sidebar'), _c('div', {
    staticClass: "wrap"
  }, [_vm._m(0), _c('Header'), _c('v-touch', {
    staticClass: "content",
    attrs: {
      "swipe-options": {
        direction: 'horizontal'
      }
    },
    on: {
      "swipe": _vm.handleSwipe
    }
  }, [_c('transition', {
    attrs: {
      "name": "router-animation"
    }
  }, [_vm.$route.name !== 'Layout' ? _c('router-view') : _c('h1', [_vm._v("Redstone Finance App")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "banner"
  }, [_vm._v(" ⭐️ If you like RedStone, join our "), _c('a', {
    attrs: {
      "href": "https://discord.com/invite/PVxBZKFr46",
      "target": "_blank",
      "referrerpolicy": "no-referrer"
    }
  }, [_vm._v(" Discord")]), _vm._v(" and follow us on "), _c('a', {
    attrs: {
      "href": "https://twitter.com/redstone_defi",
      "target": "_blank",
      "referrerpolicy": "no-referrer"
    }
  }, [_vm._v(" Twitter")]), _vm._v(" ⭐️ ")]);

}]

export { render, staticRenderFns }