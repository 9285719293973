var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "feeds"
  }, [_vm._m(0), _c('div', {
    staticClass: "feeds__actions-wrapper"
  }, [_c('div', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "feeds__actions-wrapper-item"
  }, [_c('NetworkPicker', {
    staticClass: "feeds__network-picker",
    attrs: {
      "value": _vm.selectedNetworks,
      "items": _vm.networksMap
    },
    on: {
      "input": function input($event) {
        return _vm.handleFilter('networks', $event);
      }
    }
  }), _c('CryptoPicker', {
    staticClass: "feeds__crypto-picker",
    attrs: {
      "items": _vm.cryptoImages,
      "value": _vm.selectedCryptos
    },
    on: {
      "input": function input($event) {
        return _vm.handleFilter('cryptos', $event);
      }
    }
  }), _c('div', {
    staticClass: "feeds__status"
  }, [_c('div', {
    staticClass: "feeds__actions-wrapper-label mr-4 ml-4 text-light fw-normal"
  }, [_c('span', {
    staticClass: "feeds__status-text"
  }, [_vm._v("Selected networks: "), _c('strong', [_vm._v(_vm._s(_vm.selectedNetworks.length || _vm.networksMap.length))])]), _c('span', {
    staticClass: "feeds__status-text"
  }, [_vm._v("Selected feeds: "), _c('strong', [_vm._v(_vm._s(_vm.selectedCryptos.length || _vm.cryptoImages.length))])])]), _c('div', {
    staticClass: "feeds__actions-wrapper-label mr-4 text-light fw-normal"
  })]), _c('div', {
    staticClass: "feeds__actions-wrapper-label text-light fw-normal",
    staticStyle: {
      "margin-left": "auto"
    }
  }, [_c('span', {
    staticClass: "feeds__status-text mb-2"
  }, [_vm._v(" Feeds per page: "), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.perPage,
      expression: "perPage"
    }],
    staticClass: "feeds__select",
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.perPage = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }, _vm.onPerPageChange]
    }
  }, _vm._l(_vm.perPageOptions, function (option) {
    return _c('option', {
      key: option,
      domProps: {
        "value": option
      }
    }, [_vm._v(" " + _vm._s(option) + " ")]);
  }), 0)]), _c('span', {
    staticClass: "feeds__status-text",
    staticStyle: {
      "text-align": "right"
    }
  }, [_vm._v(" Current page: "), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedPage,
      expression: "selectedPage"
    }],
    staticClass: "feeds__select",
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.selectedPage = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }, _vm.onSelectedPageChange]
    }
  }, _vm._l(_vm.availablePages, function (page) {
    return _c('option', {
      key: page,
      domProps: {
        "value": page
      }
    }, [_vm._v(" " + _vm._s(page) + " ")]);
  }), 0)])])], 1), _c('div', {
    staticClass: "feeds__actions-wrapper-item"
  }, [_vm.selectedNetworks.length > 0 ? _c('div', [_c('div', {
    staticClass: "selected-items"
  }, [_vm._v("Selected networks:")]), _c('div', {
    staticClass: "selected-filters-icons"
  }, [_c('SelectedFilters', {
    attrs: {
      "filters": _vm.displayedSelectedNetworks
    },
    on: {
      "remove": _vm.removeNetwork
    }
  })], 1)]) : _vm._e(), _vm.selectedCryptos.length > 0 && _vm.selectedNetworks.length > 0 ? _c('div', {
    staticClass: "separator"
  }) : _vm._e(), _vm.selectedCryptos.length > 0 ? _c('div', [_c('div', {
    staticClass: "selected-items"
  }, [_vm._v("Selected feeds:")]), _c('div', {
    staticClass: "selected-filters-icons"
  }, [_c('SelectedFilters', {
    attrs: {
      "filters": _vm.displayedSelectedCryptos
    },
    on: {
      "remove": _vm.removeCrypto
    }
  })], 1)]) : _vm._e()]), _vm.hasFiltersAndSearch ? _c('div', {
    staticClass: "clear-filters",
    on: {
      "click": _vm.resetFilters
    }
  }, [_vm._v(" Clear all ")]) : _vm._e()])]), _c('FeedsTable', {
    ref: "tableComponent",
    attrs: {
      "is-loading": _vm.isLoading,
      "filters": _vm.filters,
      "feeds": _vm.feeds,
      "per-page": _vm.perPage,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "current-page": _vm.currentPage
    },
    on: {
      "change": function change(value) {
        return _vm.displayedTableItems = value;
      },
      "update:sort": _vm.handleSort,
      "update:filteredItems": _vm.onFiltered
    }
  }), !_vm.isLoading ? _c('b-pagination', {
    staticClass: "my-3 custom-pagination",
    attrs: {
      "prev-text": _vm.prevIcon,
      "next-text": _vm.nextIcon,
      "limit": "1",
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "fill"
    },
    on: {
      "change": _vm.onPageChange
    },
    scopedSlots: _vm._u([{
      key: "page",
      fn: function fn(_ref) {
        var page = _ref.page;
        return [page === _vm.currentPage ? _c('span', {
          staticClass: "entry-info"
        }, [_vm.totalRows > 0 ? _c('span', [_vm._v(" Showing " + _vm._s(_vm.firstEntry) + " to " + _vm._s(_vm.lastEntry) + " of " + _vm._s(_vm.totalRows) + " entries ")]) : _c('span', [_vm._v("No entries found "), _vm.hasFiltersAndSearch ? _c('span', [_vm._v(" - ")]) : _vm._e(), _vm.hasFiltersAndSearch ? _c('span', {
          staticClass: "clear-filters",
          staticStyle: {
            "pointer-events": "all"
          },
          on: {
            "click": _vm.resetFilters
          }
        }, [_vm._v("Clear filters")]) : _vm._e()])]) : _c('span', [_vm._v(_vm._s(page))])];
      }
    }], null, false, 919954545),
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "feeds__view-details"
  }, [_c('h1', [_vm._v("Push Model (On-chain Feeds)")]), _c('p', [_vm._v(" RedStone Push Oracle offers robust, reliable and accurate data feeds available to query from the destination network. ")])]);

}]

export { render, staticRenderFns }