var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "provider d-flex flex-column"
  }, [_vm.provider ? _c('div') : _c('div', {
    staticClass: "preloaders"
  }, [_c('div', {
    staticClass: "preloader logo-preloader"
  }), _c('div', {
    staticClass: "preloader text-preloader"
  })]), _c('div', {
    staticClass: "pull-model"
  }, [_vm._m(0), _c('DataServiceDetails', {
    attrs: {
      "provider": _vm.provider
    }
  })], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pull-model__view-details"
  }, [_c('h1', [_vm._v("Pull Model")]), _c('p', [_vm._v(" RedStone Pull Oracle offers robust, high frequency of data updates for broad range of assets. ")])]);

}]

export { render, staticRenderFns }