var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.feeds && !_vm.isLoading ? _c('b-table', {
    key: "table",
    ref: "selectableTable",
    staticClass: "feeds__table",
    attrs: {
      "id": "feeds-table",
      "stacked": "md",
      "sortBy": _vm.sortBy,
      "sortDesc": _vm.sortDesc,
      "filter": _vm.filters,
      "sort-icon-left": "",
      "sticky-header": "500px",
      "hover": "",
      "items": _vm.feeds,
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "filter-function": _vm.customFilter,
      "fields": _vm.fields
    },
    on: {
      "input": _vm.onChange,
      "filtered": _vm.onFiltered,
      "sort-changed": _vm.handleSort
    },
    scopedSlots: _vm._u([{
      key: "head(deviation)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.label) + " "), _c('i', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "fa fa-info-circle",
          attrs: {
            "title": "Deviation threshold - the system triggers an update when a node detects that the off-chain data has diverged from the on-chain value beyond a predetermined threshold difference."
          }
        })];
      }
    }, {
      key: "head(timestamp)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.label) + " "), _c('i', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "fa fa-info-circle",
          attrs: {
            "title": "Time since last on-chain price update. Indicates data freshness and helps track update frequency."
          }
        })];
      }
    }, {
      key: "head(heartbeat)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.label) + " "), _c('i', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "fa fa-info-circle",
          attrs: {
            "title": "An automatic timing system forces an on-chain price update when it counts down to 00:00. This acts as a safety net, ensuring updates happen even if price changes stay within the allowed range during the entire heartbeat period."
          }
        })];
      }
    }, {
      key: "cell(network)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('img', {
          staticClass: "feeds__token-image",
          attrs: {
            "src": item.network.image,
            "alt": item.network.name
          }
        }), _vm._v(" " + _vm._s(item.network.name) + " ")];
      }
    }, {
      key: "cell(contract_address)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.feed_address && item.explorer && item.feed_address != '__NO_FEED__' ? _c('div', [_c('a', {
          staticClass: "feeds__contract-address",
          attrs: {
            "title": "Open address in ".concat(item.explorer.name, " explorer"),
            "target": "_blank",
            "href": "".concat(item.explorer.explorerUrl, "/address/").concat(item.feed_address)
          }
        }, [_vm._v(" " + _vm._s(_vm.truncateString(item.feed_address)) + " ")]), _c('CopyToClipboard', {
          attrs: {
            "copy-text": "Copy feed address",
            "value": item.feed_address
          }
        })], 1) : item.contract_address && item.explorer ? _c('div', [_c('a', {
          staticClass: "feeds__contract-address",
          attrs: {
            "title": "Open address in ".concat(item.explorer.name, " explorer"),
            "target": "_blank",
            "href": "".concat(item.explorer.explorerUrl, "/address/").concat(item.contract_address)
          }
        }, [_vm._v(" " + _vm._s(_vm.truncateString(item.contract_address)) + " ")]), _c('CopyToClipboard', {
          attrs: {
            "copy-text": "Copy adapter address",
            "value": item.contract_address
          }
        })], 1) : _vm._e()];
      }
    }, {
      key: "cell(feed)",
      fn: function fn(_ref3) {
        var _item$token_image;
        var item = _ref3.item;
        return [_c('img', {
          staticClass: "feeds__token-image",
          attrs: {
            "src": (_item$token_image = item.token_image) === null || _item$token_image === void 0 ? void 0 : _item$token_image.logoURI,
            "alt": item.feed
          }
        }), _c('RouterLink', {
          attrs: {
            "to": {
              name: 'SingleFeed',
              params: {
                network: _vm.toUrlParam(item.network.name),
                token: _vm.toUrlParam(item.token)
              }
            }
          }
        }, [_vm._v(_vm._s(_vm.clearFeedName(item.feed)))]), _c('FeedIcon', {
          attrs: {
            "isFundamentalFeed": item.isFundamentalFeed,
            "isTwap30": item.isTwap30,
            "isTwap60": item.isTwap60
          }
        })];
      }
    }, {
      key: "cell(answer)",
      fn: function fn(_ref4) {
        var _item$apiValues, _item$loaders;
        var item = _ref4.item;
        return [(_item$apiValues = item.apiValues) !== null && _item$apiValues !== void 0 && _item$apiValues.value ? _c('strong', {
          staticStyle: {
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(item.apiAnswer))]) : (_item$loaders = item.loaders) !== null && _item$loaders !== void 0 && _item$loaders.feedDataValue ? _c('Loader', {
          staticClass: "feeds__loader"
        }) : item.answer ? _c('span', [_c('strong', {
          staticStyle: {
            "font-weight": "500"
          }
        }, [_vm._v(" " + _vm._s(item.contractAnswer) + " ")])]) : _c('span', {
          staticClass: "feeds__no-data"
        }, [_vm._v("no-data")])];
      }
    }, {
      key: "cell(heartbeat)",
      fn: function fn(_ref5) {
        var _item$loaders2, _item$apiValues2;
        var item = _ref5.item;
        return [(_item$loaders2 = item.loaders) !== null && _item$loaders2 !== void 0 && _item$loaders2.blockTimestamp && (item === null || item === void 0 ? void 0 : (_item$apiValues2 = item.apiValues) === null || _item$apiValues2 === void 0 ? void 0 : _item$apiValues2.timestamp) == null ? _c('Loader', {
          staticClass: "feeds__loader"
        }) : _c('span', {
          staticClass: "feeds__timestamp"
        }, [_vm.heartbeatIsNumber(item.heartbeat) ? _c('span', [_c('div', {
          staticStyle: {
            "display": "flex",
            "align-items": "center",
            "justify-content": "center"
          }
        }, [_c('span', [_vm._v(" " + _vm._s(item.heartbeatTitle))]), _c('to-date-counter', {
          staticClass: "ml-2",
          attrs: {
            "interval": item.heartbeatInterval,
            "duration": item.heartbeat
          }
        })], 1)]) : _c('div', [_c('span', {
          staticClass: "cron-trigger",
          attrs: {
            "id": "cron-trigger-".concat(item.layer_id)
          }
        }, [_c('span', [_vm._v("Cron: " + _vm._s(item.heartbeatTitle))]), _c('cron-counter', {
          staticClass: "mt-2",
          attrs: {
            "crons": item.heartbeat
          }
        })], 1)])])];
      }
    }], null, false, 3893853774)
  }) : _c('vue-loaders-ball-beat', {
    staticClass: "table-loader",
    attrs: {
      "color": "var(--redstone-red-color)",
      "scale": "0.5"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }