var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.item.feed_address && _vm.item.explorer && _vm.item.feed_address != '__NO_FEED__' ? _c('div', {
    class: {
      'separate-labels': _vm.separateLabels
    }
  }, [_c('div', {
    staticClass: "d-inline",
    class: {
      'label': _vm.separateLabels
    }
  }, [_vm._v(_vm._s(_vm.separateLabels ? 'Price:' : 'Price feed address:'))]), _c('div', {
    staticClass: "d-inline"
  }, [_c('a', {
    staticClass: "feeds__contract-address",
    attrs: {
      "title": "Open address in ".concat(_vm.item.explorer.name, " explorer"),
      "target": "_blank",
      "href": "".concat(_vm.item.explorer.explorerUrl, "/address/").concat(_vm.item.feed_address)
    }
  }, [_vm._v(" " + _vm._s(_vm.truncateString(_vm.item.feed_address, _vm.disableTruncate)) + " ")]), _c('CopyToClipboard', {
    attrs: {
      "copy-text": "Copy feed address",
      "value": _vm.item.feed_address
    }
  })], 1)]) : _c('div', {
    class: {
      'separate-labels': _vm.separateLabels
    }
  }, [_c('div', {
    staticClass: "d-inline",
    class: {
      'label': _vm.separateLabels
    }
  }, [_vm._v(_vm._s(_vm.separateLabels ? 'Adapter:' : 'Adapter address:'))]), _c('div', {
    staticClass: "d-inline"
  }, [_c('a', {
    staticClass: "feeds__contract-address",
    attrs: {
      "title": "Open address in ".concat(_vm.item.explorer.name, " explorer"),
      "target": "_blank",
      "href": "".concat(_vm.item.explorer.explorerUrl, "/address/").concat(_vm.item.contract_address)
    }
  }, [_vm._v(" " + _vm._s(_vm.truncateString(_vm.item.contract_address, _vm.disableTruncate)) + " ")]), _c('CopyToClipboard', {
    attrs: {
      "copy-text": "Copy adapter address",
      "value": _vm.item.contract_address
    }
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }