var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "charts-container"
  }, [_vm.sourceHasNoErrors ? _c('div', {
    staticClass: "report-ok-notification"
  }, [_vm._v(" This source had no errors during last 5 days ")]) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.hasAnyStats('fetching-failed'),
      expression: "hasAnyStats('fetching-failed')"
    }],
    staticClass: "source-chart-wrapper"
  }, [_c('div', {
    staticClass: "chart-title"
  }, [_vm._v("Fetching failed")]), _c('SourceChart', {
    attrs: {
      "stats": _vm.stats['fetching-failed'],
      "color": "rgb(255, 159, 64)",
      "title": "Fetching failed count"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.hasAnyStats('incorrect-price-value'),
      expression: "hasAnyStats('incorrect-price-value')"
    }],
    staticClass: "source-chart-wrapper"
  }, [_c('div', {
    staticClass: "chart-title"
  }, [_vm._v("Incorrect price value")]), _c('SourceChart', {
    attrs: {
      "stats": _vm.stats['incorrect-price-value'],
      "color": "rgb(255, 205, 86)",
      "title": "Incorrect price value count"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }