var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form-checkbox', {
    staticClass: "crypto-checkbox",
    attrs: {
      "disabled": _vm.disabled,
      "variant": "danger",
      "checked": _vm.isChecked
    },
    on: {
      "change": _vm.toggleCheckbox
    }
  }, [_c('b-img', {
    staticClass: "mr-1",
    attrs: {
      "title": _vm.name,
      "src": _vm.imageName,
      "alt": _vm.name,
      "width": "20",
      "height": "20"
    }
  }), _c('span', {
    attrs: {
      "title": _vm.name
    }
  }, [_vm._v(_vm._s(_vm.name))])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }