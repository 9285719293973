var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isLoading ? _c('Loader', {
    staticClass: "feeds__loader"
  }) : _vm.rawTimestamp ? _c('span', {
    staticClass: "feeds__timestamp"
  }, [_c('span', {
    staticClass: "feeds__timestamp-date"
  }, [_vm._v(" " + _vm._s(_vm.formattedDate) + " ")]), _vm._v(" " + _vm._s(_vm.parsedTimestamp) + " ago ")]) : _c('span', {
    staticClass: "feeds__no-data"
  }, [_vm._v("no-data")]);

}
var staticRenderFns = []

export { render, staticRenderFns }