var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "chart-container"
  }, [_c('div', {
    staticClass: "chart-controls"
  }, _vm._l(_vm.ranges, function (range) {
    return !_vm.duplicatedRanges.includes(range.value) ? _c('b-button', {
      key: range.value,
      class: ['range-button', {
        active: _vm.selectedRange === range.value && !_vm.isZoomed
      }],
      attrs: {
        "variant": "danger",
        "size": "sm"
      },
      on: {
        "click": function click($event) {
          return _vm.onRangeChange(range.value);
        }
      }
    }, [_vm._v(" " + _vm._s(range.label) + " ")]) : _vm._e();
  }), 1), _c('canvas', {
    ref: "chart"
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }