var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "timer-container",
    attrs: {
      "title": _vm.formattedTime || 'Heartbeat'
    }
  }, [_c('svg', {
    staticClass: "timer-svg",
    attrs: {
      "viewBox": "0 0 50 50"
    }
  }, [_c('circle', {
    staticClass: "timer-circle-bg",
    attrs: {
      "r": "20",
      "cx": "25",
      "cy": "25"
    }
  }), _c('circle', {
    staticClass: "timer-circle-progress",
    attrs: {
      "stroke-dasharray": _vm.circumference,
      "stroke-dashoffset": _vm.strokeDashoffset,
      "r": "20",
      "cx": "25",
      "cy": "25"
    }
  }), _c('path', {
    class: ['heart-icon', {
      'heart-icon--beating': _vm.isBeating
    }],
    attrs: {
      "d": "M25 36.7l-1.5-1.4c-5.3-4.8-8.8-8-8.8-11.9 0-3.2 2.5-5.7 5.7-5.7 1.8 0 3.5 0.8 4.6 2.1 1.1-1.3 2.8-2.1 4.6-2.1 3.2 0 5.7 2.5 5.7 5.7 0 3.9-3.5 7.1-8.8 11.9L25 36.7z"
    }
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }