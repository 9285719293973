var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "selected-filters-container"
  }, [_c('div', {
    staticClass: "selected-filters-flex"
  }, _vm._l(_vm.filters, function (filter) {
    return _c('div', {
      key: filter.key,
      staticClass: "selected-filters__item",
      on: {
        "click": function click($event) {
          return _vm.removeFilter(filter);
        }
      }
    }, [_c('img', {
      staticClass: "selected-filters__icon",
      attrs: {
        "src": filter.imageUrl,
        "title": "Click to remove filter: ".concat(filter.name),
        "alt": filter.name
      }
    })]);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }