var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "multiselect-dropdown-container"
  }, [_c('b-dropdown', {
    ref: "dropdown",
    staticClass: "dropdown multiselect-dropdown",
    attrs: {
      "text": _vm.buttonText,
      "multiple": ""
    },
    on: {
      "shown": _vm.onDropdownShown
    }
  }, [_c('div', {
    staticClass: "search-input-container"
  }, [_c('b-form-input', {
    ref: "searchInput",
    staticClass: "pr-4",
    attrs: {
      "placeholder": "Search..."
    },
    model: {
      value: _vm.searchQuery,
      callback: function callback($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  })], 1), _c('b-dropdown-form', [_c('b-form-checkbox-group', {
    staticClass: "checkbox-group",
    attrs: {
      "stacked": ""
    },
    model: {
      value: _vm.tempSelectedItems,
      callback: function callback($$v) {
        _vm.tempSelectedItems = $$v;
      },
      expression: "tempSelectedItems"
    }
  }, _vm._l(_vm.filteredItems, function (item) {
    return _c('b-form-checkbox', {
      key: item.value,
      staticClass: "checkbox-list-item",
      attrs: {
        "value": item.value
      }
    }, [_c('div', {
      staticClass: "item-content"
    }, [item.image ? _c('b-img', {
      staticClass: "mr-2",
      attrs: {
        "src": item.image,
        "alt": item.text,
        "width": "20",
        "height": "20"
      }
    }) : _vm._e(), _c('span', {
      staticClass: "item-text",
      attrs: {
        "title": item.text
      }
    }, [_vm._v(_vm._s(item.text))])], 1)]);
  }), 1), _vm.filteredItems.length === 0 ? _c('span', {
    staticClass: "no-results"
  }, [_vm._v("No results found")]) : _vm._e()], 1), _vm.hasChanges ? _c('div', {
    staticClass: "confirm-button-container"
  }, [_c('b-button', {
    staticClass: "confirm-button",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.confirmChanges
    }
  }, [_vm._v(" Apply (" + _vm._s(_vm.tempSelectedItems.length) + ") ")])], 1) : _vm.value.length > 0 ? _c('div', {
    staticClass: "confirm-button-container"
  }, [_c('b-button', {
    staticClass: "reset-button",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.resetChanges
    }
  }, [_vm._v(" Reset (" + _vm._s(_vm.tempSelectedItems.length) + ") ")])], 1) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }