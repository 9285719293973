var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "stat-elem"
  }, [_c('div', {
    staticClass: "stat-title"
  }, [_vm._v(_vm._s(_vm.title) + ": ")]), !_vm.isCurrencyToken ? _c('div', {
    staticClass: "stat-value"
  }, [_vm._v(" " + _vm._s(_vm.value) + " ")]) : _c('div', {
    staticClass: "stat-value"
  }, [_vm._v(" " + _vm._s(_vm._f("price")(_vm.value, {
    currency: this.currency,
    decimals: this.decimals
  })) + " ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }